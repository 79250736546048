import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faHashtag,
  faUser,
  faCommentDots,
  faThumbsUp,
  faThumbsDown,
  faReply,
} from "@fortawesome/free-solid-svg-icons";
import UserFeed from "./UserFeed";
import UserSearch from "./UserSearch";
import UserProfile from "./UserProfile";
import AdminAprove from "./AdminApprove";
import AdminApprove from "./AdminApprove";

const UserPostLayOut = () => {
  const [userSidebar, setUserSideBar] = useState("feed");
  const handleSideBar = (section) => {
    setUserSideBar(section);
  };
  const getSidebarItemStyle = (section) => ({
    backgroundColor: userSidebar === section ? "White" : "#E6ECF5",
    display: "flex",
    color: userSidebar === section ? "#3D3A49" : "#003E6B",
    justifyContent: "center",
    borderRadius: userSidebar === section ? "20px 0px 0px 20px" : "0",
    width: "100%",
    alignItems: "center",
    height: "40px",
    cursor: "pointer",
    marginRight: userSidebar === section ? "0px" : "0",
  });
  return (
    <div className="container">
      <div className="row ">
        {/* Sidebar */}
        <div
          className="col-1 my-3  d-flex flex-column align-items-center pt-4"
          style={{
            height: "100vh",
            backgroundColor: "#5898A5",
            borderRadius: "5px",
          }}
        >
          <div
            className="mb-4"
            style={getSidebarItemStyle("feed")}
            onClick={() => handleSideBar("feed")}
          >
            <FontAwesomeIcon icon={faCommentDots} size="2x" />
          </div>
          <div
            className="mb-4"
            style={getSidebarItemStyle("search")}
            onClick={() => handleSideBar("search")}
          >
            <FontAwesomeIcon icon={faSearch} size="2x" />
          </div>
          <div
            className="mb-4"
            style={getSidebarItemStyle("trending")}
            onClick={() => handleSideBar("trending")}
          >
            <FontAwesomeIcon icon={faHashtag} size="2x" />
          </div>
          <div
            className="mb-4"
            style={getSidebarItemStyle("profile")}
            onClick={() => handleSideBar("profile")}
          >
            <FontAwesomeIcon icon={faUser} size="2x" />
          </div>
          <div
            className="mb-4"
            style={getSidebarItemStyle("admin")}
            onClick={() => handleSideBar("admin")}
          >
            <FontAwesomeIcon icon={faUser} size="2x" />
          </div>
        </div>

        <div className="col-11 my-3">
          <div>
            {" "}
            {userSidebar === "feed" && (
              <div
                style={{
                  background: "#edf7f0",
                  height: "100%",
                  borderRadius: "5px",
                  padding: "20px",
                }}
              >
                <UserFeed />
              </div>
            )}
          </div>
          <div>
            {userSidebar === "search" && (
              <div
                style={{
                  background: "#edf7f0",
                  height: "100vh",
                  borderRadius: "5px",
                  padding: "20px",
                }}
              >
                <UserSearch />
              </div>
            )}
          </div>
          <div className="mb-4">
            {userSidebar === "trending" && <div>Coming.....3</div>}
          </div>
          <div>
            {userSidebar === "profile" && (
              <div
                style={{
                  background: "#edf7f0",
                  height: "100%",
                  borderRadius: "5px",
                  padding: "20px",
                }}
              >
                <UserProfile />
              </div>
            )}
          </div>
          <div>
            {userSidebar === "admin" && (
              <div
                style={{
                  background: "#edf7f0",
                  height: "100%",
                  borderRadius: "5px",
                  padding: "20px",
                }}
              >
                <AdminApprove />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPostLayOut;
