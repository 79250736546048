import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import DevelopmentProjected from "./Pages/Home/DevelopmentProjected";
import UserPostLayOut from "./Pages/Home/UserPostLayOut/UserPostLayOut";
import Title from "./Pages/Shared/Title";
import NavBar from "./Pages/Shared/NavBar";
import Fotter from "./Pages/Shared/Fotter";
import UserTimeLine from "./Pages/Home/UserPostLayOut/UserTimeLine";
import AllFeed from "./Pages/Home/UserPostLayOut/AllFeed";
import SignUp from "./Pages/SignIn/SignUp";
import SignIn from "./Pages/SignIn/SignIn";

function App() {
  return (
    <div>
      <Router>
        <Title />
        <NavBar />
        <Routes>
          <Route
            path="/developmentprojected"
            element={<DevelopmentProjected />}
          />
          <Route path="/userpost" element={<UserPostLayOut />} />
          <Route path="/usertimeline" element={<UserTimeLine />} />
          <Route path="/newsfeed" element={<AllFeed />} />
          <Route path="/signup" element={<SignUp/>} />
          <Route path="/signin" element={<SignIn/>} />

          <Route path="/" element={<Home />} />
        </Routes>
        <Fotter />
      </Router>
    </div>
  );
}

export default App;
