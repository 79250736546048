import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Banner.css"

const Banner = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const slickSlideStyle = {
    height: "100%",
  };

  const iconBoxStyle = {
    backgroundColor: "#264653",
    height: "200px",
    // marginLeft: "0px 10px",
    width: "90%",
    display: "flex",
    justifyContent: "center",
    
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "10px",
  };

  const iconStyle = {
    fontSize: "2rem", // Icon size
    color: "#ffffff", // Icon color
    marginBottom: "10px",
  };

  const titleStyle = {
    fontSize: "1.2rem", // Text size
    color: "#ffffff", // Text color
    textAlign: "center",
  };

  return (
    <section id="hero" className="hero">
      <div className="container position-relative">
        <div className="row gy-5" data-aos="fade-in">
          <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start">
            <h2>
              Welcome to <span>Hasan's IT Solution</span>
            </h2>
            <p>
              Leading Website, Mobile App, IoT, and Custom Software Solutions
              Company In Bangladesh.
            </p>
            <div className="d-flex justify-content-center justify-content-lg-start">
              <a href="#about" className="btn-get-started text-decoration-none">
                Get Started
              </a>
              <a
                className="glightbox btn-watch-video d-flex align-items-center text-decoration-none"
                href="#"
              >
                <i className="bi bi-play-circle"></i>
                <span>Watch Video</span>
              </a>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <img
              src="../src/Pages/assets/img/hero-img.svg"
              className="img-fluid"
              alt=""
              data-aos="zoom-out"
              data-aos-delay="100"
            />
          </div>
        </div>
      </div>

      <div className="icon-boxes position-relative pt-5">
        <div className="container position-relative">
          <Slider {...settings}>
            <div className="slick-slide" style={slickSlideStyle}>
              <div className="icon-box" style={iconBoxStyle}>
                <div className="icon" style={iconStyle}>
                  <i className="bi bi-easel"></i>
                </div>
                <h4 className="title" style={titleStyle}>
                  <a
                    href="https://www.linkedin.com/company/102041279/admin/dashboard/"
                    target="_blank"
                    className="stretched-link text-decoration-none"
                  >
                    Portfolio
                  </a>
                </h4>
              </div>
            </div>

            <div className="slick-slide" style={slickSlideStyle}>
              <div className="icon-box" style={iconBoxStyle}>
                <div className="icon" style={iconStyle}>
                  <i className="bi bi-gem"></i>
                </div>
                <h4 className="title" style={titleStyle}>
                  <a
                    href="https://www.facebook.com/skillhubpage"
                    target="_blank"
                    className="stretched-link text-decoration-none"
                  >
                    SkillHub
                  </a>
                </h4>
              </div>
            </div>

            <div className="slick-slide" style={slickSlideStyle}>
              <div className="icon-box" style={iconBoxStyle}>
                <div className="icon" style={iconStyle}>
                  <i className="bi bi-geo-alt"></i>
                </div>
                <h4 className="title" style={titleStyle}>
                  <a
                    href="https://www.ukilzone.com/"
                    target="_blank"
                    className="stretched-link text-decoration-none"
                  >
                    Ukilzone.com
                  </a>
                </h4>
              </div>
            </div>

            {/* <div className="slick-slide" style={slickSlideStyle}>
              <div className="icon-box" style={iconBoxStyle}>
                <div className="icon" style={iconStyle}>
                  <i className="bi bi-command"></i>
                </div>
                <h4 className="title" style={titleStyle}>
                  <a
                    href="/developmentProjected"
                    target="_blank"
                    className="stretched-link text-decoration-none"
                  >
                    HIT Lab
                  </a>
                </h4>
              </div>
            </div> */}

            {/* <div className="slick-slide" style={slickSlideStyle}>
              <div className="icon-box" style={iconBoxStyle}>
                <div className="icon" style={iconStyle}>
                  <i className="bi bi-command"></i>
                </div>
                <h4 className="title" style={titleStyle}>
                  <a
                    href="/signin"
                    target="_blank"
                    className="stretched-link text-decoration-none"
                  >
                    The voice of Bengal
                  </a>
                </h4>
              </div>
            </div> */}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Banner;
