import React from "react";

const AdminApprove = () => {
  return (
    <div>
      {/* proifle post */}
      <div className="pt-5">
        {/* title  */}
        <div>
          <h4
            style={{
              textAlign: "left",
              color: "#6E89AD",
            }}
          >
            Threads Verification
          </h4>
        </div>
        {/* post 1 */}
        <div
          style={{
            border: "1px solid #6E89AD",
            borderRadius: "3px",
            padding: "5px 10px 10px 20px",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              textAlign: "left",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <i
                style={{
                  color: "#6E89AD",
                  fontSize: "50px",
                  textAlign: "center",
                }}
                className="bi bi-person-circle"
              ></i>
            </div>

            <div style={{ paddingLeft: "15px" }}>
              <h4 style={{ color: "#6E89AD", fontWeight: 600 }}>Abc Xyz</h4>
              <span>10/12/2024</span>
            </div>
          </div>
          <div style={{ border: "2px solid #6E89AD", height: "108px" }}>
            <span style={{ color: "#6E89AD", textAlign: "left !important" }}>
              Write your thoughts here
            </span>
            <div
              style={{
                borderTop: "2px solid #6E89AD",
                marginTop: "35px",
                marginBottom: "10px",
                paddingLeft: "10px",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <i
                    style={{ fontSize: "25px", color: "green" }}
                    className="bi bi-arrow-up-circle-fill"
                  ></i>
                  <i
                    style={{
                      fontSize: "25px",
                      color: "black",
                      paddingLeft: "10px",
                    }}
                    className="bi bi-arrow-down-circle-fill"
                  ></i>
                  <i
                    style={{
                      fontSize: "25px",
                      color: "#FF5733",
                      paddingLeft: "10px",
                    }}
                    className="bi bi-share-fill"
                  ></i>
                  <button
                    style={{
                      color: "white",
                      background: "#6E89AD",
                      borderRadius: "10px",
                      border: "none",
                      padding: "3px",
                      marginLeft: "10px",
                    }}
                  >
                    Comment
                  </button>
                </div>
                <div className="my-1 px-1">
                  <button
                    style={{
                      border: "1px solid #6E89AD",
                      borderRadius: "15px",
                      padding: "3px 10px 5px 5px",
                    }}
                  >
                    Education
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="my-5">
            <input
              type="text"
              placeholder="Remarks..."
              style={{
                background: "#A0CFDC",
                height: "30px",
                borderRadius: "10px",
                outline: "none",
                paddingLeft: "10px",
                border: "none",
                width: "350px",
              }}
            />
            <div
              style={{
             paddingTop:"10px",
             marginLeft:"150px",
              }}
            >
              <button
                style={{
                  height: "30px",
                  width: "100px",
                  borderRadius: "10px",
                  border: "none",
                  background: "green",
                  fontSize: "15px",
                  color: "white",
                }}
              >
                Approve
              </button>
              <button
                style={{
                  height: "30px",
                  marginLeft: "5px",
                  width: "100px",
                  borderRadius: "10px",
                  border: "none",
                  background: "red",
                  fontSize: "15px",
                  color: "white",
                }}
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminApprove;
