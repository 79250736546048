import React from "react";

const UserFeed = () => {
  return (
    <div>
      <div>
        <h4
          style={{
            textAlign: "left",
            color: "#6E89AD",
            textDecoration: "underline",
          }}
        >
          Amder Dhabi
        </h4>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop: "15px",
        }}
      >
        <div style={{ textAlign: "left" }}>
          <i
            style={{ color: "#6E89AD", fontSize: "50px", textAlign: "center" }}
            className="bi bi-person-circle"
          ></i>
          <p>@username</p>
        </div>
        <div
          style={{
            marginLeft: "490px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <textarea
            name=""
            style={{
              background: "white",
              borderRadius: "5px",
              width: "400px",
              outline: "none",
              resize: "none",
            }}
            placeholder="Raise Your voice..."
            id=""
          ></textarea>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <button
              style={{
                color: "white",
                background: "#6E89AD",
                paddingTop: "3px",
                width: "100px",
                borderRadius: "5px",
                border: "0px",
              }}
            >
              Post
            </button>
          </div>
        </div>
      </div>

      {/* post 1 */}
      <div
        style={{
          border: "1px solid #6E89AD",
          borderRadius: "3px",
          padding: "5px 10px 10px 20px",
        }}
      >
        <div
          style={{ textAlign: "left", display: "flex", alignItems: "center" }}
        >
          <div>
            <i
              style={{
                color: "#6E89AD",
                fontSize: "50px",
                textAlign: "center",
              }}
              className="bi bi-person-circle"
            ></i>
          </div>

          <div style={{ paddingLeft: "15px" }}>
            <h4 style={{ color: "#6E89AD", fontWeight: 600 }}>Abc Xyz</h4>
            <span>10/12/2024</span>
          </div>
        </div>
        <div style={{ border: "2px solid #6E89AD", height: "108px" }}>
          <span style={{ color: "#6E89AD", textAlign: "left" }}>
            In the midst of daily chaos, let your ideas shine and make waves.
            Your voice has the power to spark change and inspire new
            possibilities.
          </span>
          <div
            style={{
              borderTop: "2px solid #6E89AD",
              marginTop: "7px",
              marginBottom: "10px",
              paddingLeft: "10px",
            }}
          >
            <div style={{ textAlign: "left" }}>
              <i
                style={{ fontSize: "25px", color: "green" }}
                className="bi bi-arrow-up-circle-fill"
              ></i>
              <i
                style={{
                  fontSize: "25px",
                  color: "black",
                  paddingLeft: "10px",
                }}
                className="bi bi-arrow-down-circle-fill"
              ></i>
              <i
                style={{
                  fontSize: "25px",
                  color: "#FF5733",
                  paddingLeft: "10px",
                }}
                className="bi bi-share-fill"
              ></i>
              <button
                style={{
                  color: "white",
                  background: "#6E89AD",
                  borderRadius: "10px",
                  border: "none",
                  padding: "3px",
                  marginLeft: "10px",
                }}
              >
                Comment
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* post 2 */}
      <div
        style={{
          border: "1px solid #6E89AD",
          borderRadius: "3px",
          padding: "5px 10px 10px 20px",
          marginTop: "5px",
        }}
      >
        <div
          style={{ textAlign: "left", display: "flex", alignItems: "center" }}
        >
          <div>
            <i
              style={{
                color: "#6E89AD",
                fontSize: "50px",
                textAlign: "center",
              }}
              className="bi bi-person-circle"
            ></i>
          </div>

          <div style={{ paddingLeft: "15px" }}>
            <h4 style={{ color: "#6E89AD", fontWeight: 600 }}>Abc Xyz</h4>
            <span>10/12/2024</span>
          </div>
        </div>
        <div style={{ border: "2px solid #6E89AD", height: "108px" }}>
          <span style={{ color: "#6E89AD", textAlign: "left" }}>
            In the midst of daily chaos, let your ideas shine and make waves.
            Your voice has the power to spark change and inspire new
            possibilities.
          </span>
          <div
            style={{
              borderTop: "2px solid #6E89AD",
              marginTop: "7px",
              marginBottom: "10px",
              paddingLeft: "10px",
            }}
          >
            <div style={{ textAlign: "left" }}>
              <i
                style={{ fontSize: "25px", color: "green" }}
                className="bi bi-arrow-up-circle-fill"
              ></i>
              <i
                style={{
                  fontSize: "25px",
                  color: "black",
                  paddingLeft: "10px",
                }}
                className="bi bi-arrow-down-circle-fill"
              ></i>
              <i
                style={{
                  fontSize: "25px",
                  color: "#FF5733",
                  paddingLeft: "10px",
                }}
                className="bi bi-share-fill"
              ></i>
              <button
                style={{
                  color: "white",
                  background: "#6E89AD",
                  borderRadius: "10px",
                  border: "none",
                  padding: "3px",
                  marginLeft: "10px",
                }}
              >
                Comment
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* post 2 */}
      <div
        style={{
          border: "1px solid #6E89AD",
          borderRadius: "3px",
          padding: "5px 10px 10px 20px",
          marginTop: "5px",
        }}
      >
        <div
          style={{ textAlign: "left", display: "flex", alignItems: "center" }}
        >
          <div>
            <i
              style={{
                color: "#6E89AD",
                fontSize: "50px",
                textAlign: "center",
              }}
              className="bi bi-person-circle"
            ></i>
          </div>

          <div style={{ paddingLeft: "15px" }}>
            <h4 style={{ color: "#6E89AD", fontWeight: 600 }}>Abc Xyz</h4>
            <span>10/12/2024</span>
          </div>
        </div>
        <div style={{ border: "2px solid #6E89AD", height: "108px" }}>
          <span style={{ color: "#6E89AD", textAlign: "left" }}>
            In the midst of daily chaos, let your ideas shine and make waves.
            Your voice has the power to spark change and inspire new
            possibilities.
          </span>
          <div
            style={{
              borderTop: "2px solid #6E89AD",
              marginTop: "7px",
              marginBottom: "10px",
              paddingLeft: "10px",
            }}
          >
            <div style={{ textAlign: "left" }}>
              <i
                style={{ fontSize: "25px", color: "green" }}
                className="bi bi-arrow-up-circle-fill"
              ></i>
              <i
                style={{
                  fontSize: "25px",
                  color: "black",
                  paddingLeft: "10px",
                }}
                className="bi bi-arrow-down-circle-fill"
              ></i>
              <i
                style={{
                  fontSize: "25px",
                  color: "#FF5733",
                  paddingLeft: "10px",
                }}
                className="bi bi-share-fill"
              ></i>
              <button
                style={{
                  color: "white",
                  background: "#6E89AD",
                  borderRadius: "10px",
                  border: "none",
                  padding: "3px",
                  marginLeft: "10px",
                }}
              >
                Comment
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* post 2 */}
      <div
        style={{
          border: "1px solid #6E89AD",
          borderRadius: "3px",
          padding: "5px 10px 10px 20px",
          marginTop: "5px",
        }}
      >
        <div
          style={{ textAlign: "left", display: "flex", alignItems: "center" }}
        >
          <div>
            <i
              style={{
                color: "#6E89AD",
                fontSize: "50px",
                textAlign: "center",
              }}
              className="bi bi-person-circle"
            ></i>
          </div>

          <div style={{ paddingLeft: "15px" }}>
            <h4 style={{ color: "#6E89AD", fontWeight: 600 }}>Abc Xyz</h4>
            <span>10/12/2024</span>
          </div>
        </div>
        <div style={{ border: "2px solid #6E89AD", height: "108px" }}>
          <span style={{ color: "#6E89AD", textAlign: "left" }}>
            In the midst of daily chaos, let your ideas shine and make waves.
            Your voice has the power to spark change and inspire new
            possibilities.
          </span>
          <div
            style={{
              borderTop: "2px solid #6E89AD",
              marginTop: "7px",
              marginBottom: "10px",
              paddingLeft: "10px",
            }}
          >
            <div style={{ textAlign: "left" }}>
              <i
                style={{ fontSize: "25px", color: "green" }}
                className="bi bi-arrow-up-circle-fill"
              ></i>
              <i
                style={{
                  fontSize: "25px",
                  color: "black",
                  paddingLeft: "10px",
                }}
                className="bi bi-arrow-down-circle-fill"
              ></i>
              <i
                style={{
                  fontSize: "25px",
                  color: "#FF5733",
                  paddingLeft: "10px",
                }}
                className="bi bi-share-fill"
              ></i>
              <button
                style={{
                  color: "white",
                  background: "#6E89AD",
                  borderRadius: "10px",
                  border: "none",
                  padding: "3px",
                  marginLeft: "10px",
                }}
              >
                Comment
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserFeed;
