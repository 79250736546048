import React from "react";

const UserProfile = () => {
  return (
    <div>
      <div>
        <div style={{ textAlign: "left" }}>
          <i
            style={{ color: "#6E89AD", fontSize: "100px", textAlign: "center" }}
            className="bi bi-person-circle"
          ></i>
        </div>
        <div
          style={{
            border: "2px solid #7FB0CB",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          <div
            className="pt-2"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <h4 style={{ textDecoration: "underline" }}>
              User Profile Details
            </h4>
            <span
              style={{ fontSize: "20px", fontWeight: 600, cursor: "pointer" }}
            >
              Edit <i className="bi bi-pencil-square"></i>
            </span>
          </div>
          {/* profile info  */}
          <div>
            <div className="pt-3" style={{ width: "100%", display: "flex" }}>
              <div
                style={{
                  borderBottom: "2px solid #7FB0CB",
                  width: "33%",
                  textAlign: "left",
                  padding: "0",
                }}
              >
                <span
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Name
                </span>
                <p style={{ fontSize: "18px", color: "black" }}>Jhon do</p>
              </div>
              <div
                style={{
                  borderBottom: "2px solid #7FB0CB",
                  width: "30%",
                  textAlign: "left",
                  marginLeft: "30px",
                }}
              >
                <span
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  username@
                </span>
                <p style={{ fontSize: "18px", color: "black" }}>sakhwat@123</p>
              </div>
              <div
                style={{
                  borderBottom: "2px solid #7FB0CB",
                  width: "30%",
                  textAlign: "left",
                  marginLeft: "30px",
                }}
              >
                <span
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Email
                </span>
                <p style={{ fontSize: "18px", color: "black" }}>
                  info@gmail.com
                </p>
              </div>
            </div>
            <div className="pt-5" style={{ width: "100%", display: "flex" }}>
              <div
                style={{
                  borderBottom: "2px solid #7FB0CB",
                  width: "33%",
                  textAlign: "left",
                  padding: "0",
                }}
              >
                <span
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Phone Number
                </span>
                <p style={{ fontSize: "18px", color: "black" }}>
                  +8801302086413
                </p>
              </div>
              <div
                style={{
                  borderBottom: "2px solid #7FB0CB",
                  width: "30%",
                  textAlign: "left",
                  marginLeft: "30px",
                }}
              >
                <span
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Address
                </span>
                <p style={{ fontSize: "18px", color: "black" }}>
                  Dhaka Bangladesh
                </p>
              </div>
              <div
                style={{
                  borderBottom: "2px solid #7FB0CB",
                  width: "30%",
                  textAlign: "left",
                  marginLeft: "30px",
                }}
              >
                <span
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Password
                </span>
                <p style={{ fontSize: "18px", color: "black" }}>******</p>
              </div>
            </div>
          </div>
        </div>
        {/* proifle search  */}
        <div className="pt-5">
          {/* title  */}
          <div>
            <h4
              style={{
                textAlign: "left",
                color: "#6E89AD",
                textDecoration: "underline",
              }}
            >
              Me Feed
            </h4>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <textarea
                name=""
                style={{
                  background: "white",
                  borderRadius: "5px",
                  width: "100%",
                  height: "100px",
                  outline: "none",
                  resize: "none",
                }}
                placeholder="Raise Your voice..."
                id=""
              ></textarea>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "right",
                }}
              >
                <button
                  style={{
                    color: "white",
                    background: "#6E89AD",
                    paddingTop: "3px",
                    width: "100px",
                    borderRadius: "5px",
                    border: "0px",
                  }}
                >
                  Post
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* proifle post */}
        <div className="pt-5">
          {/* title  */}
          <div>
            <h4
              style={{
                textAlign: "left",
                color: "#6E89AD",
                textDecoration: "underline",
              }}
            >
              Pervious Posts
            </h4>
          </div>
          {/* post 1 */}
          <div
            style={{
              border: "1px solid #6E89AD",
              borderRadius: "3px",
              padding: "5px 10px 10px 20px",
              marginTop:"20px",
            }}
          >
            <div
              style={{
                textAlign: "left",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <i
                  style={{
                    color: "#6E89AD",
                    fontSize: "50px",
                    textAlign: "center",
                  }}
                  className="bi bi-person-circle"
                ></i>
              </div>

              <div style={{ paddingLeft: "15px" }}>
                <h4 style={{ color: "#6E89AD", fontWeight: 600 }}>Abc Xyz</h4>
                <span>10/12/2024</span>
              </div>
            </div>
            <div style={{ border: "2px solid #6E89AD", height: "108px" }}>
              <span style={{ color: "#6E89AD", textAlign: "left" }}>
                In the midst of daily chaos, let your ideas shine and make
                waves. Your voice has the power to spark change and inspire new
                possibilities.
              </span>
              <div
                style={{
                  borderTop: "2px solid #6E89AD",
                  marginTop: "7px",
                  marginBottom: "10px",
                  paddingLeft: "10px",
                }}
              >
                <div style={{ textAlign: "left" }}>
                  <i
                    style={{ fontSize: "25px", color: "green" }}
                    className="bi bi-arrow-up-circle-fill"
                  ></i>
                  <i
                    style={{
                      fontSize: "25px",
                      color: "black",
                      paddingLeft: "10px",
                    }}
                    className="bi bi-arrow-down-circle-fill"
                  ></i>
                  <i
                    style={{
                      fontSize: "25px",
                      color: "#FF5733",
                      paddingLeft: "10px",
                    }}
                    className="bi bi-share-fill"
                  ></i>
                  <button
                    style={{
                      color: "white",
                      background: "#6E89AD",
                      borderRadius: "10px",
                      border: "none",
                      padding: "3px",
                      marginLeft: "10px",
                    }}
                  >
                    Comment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
