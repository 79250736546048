import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const AllFeed = () => {
  const [posts, setPosts] = useState([
    {
      id: 1,
      user: "John Doe",
      avatar: "https://via.placeholder.com/50",
      date: "August 16, 2024",
      content: "Had a great day at the beach!",
      likes: 24,
      dislikes: 2,
      comments: [{ id: 1, text: "Looks fun!" }],
    },
    {
      id: 2,
      user: "Jane Smith",
      avatar: "https://via.placeholder.com/50",
      date: "August 15, 2024",
      content: "Just finished reading a fantastic book!",
      likes: 15,
      dislikes: 1,
      comments: [{ id: 1, text: "Which book?" }],
    },
  ]);

  const [openComments, setOpenComments] = useState(null);
  const [editingComment, setEditingComment] = useState({ postId: null, commentId: null, text: '' });
  const inputRefs = useRef({});

  const toggleComments = (postId) => {
    setOpenComments(openComments === postId ? null : postId);
  };

  const addComment = (postId, commentText) => {
    const updatedPosts = posts.map((post) => {
      if (post.id === postId) {
        return {
          ...post,
          comments: [
            ...post.comments,
            { id: post.comments.length + 1, text: commentText },
          ],
        };
      }
      return post;
    });
    setPosts(updatedPosts);
  };

  const likePost = (postId) => {
    const updatedPosts = posts.map((post) => {
      if (post.id === postId) {
        return { ...post, likes: post.likes + 1 };
      }
      return post;
    });
    setPosts(updatedPosts);
  };

  const dislikePost = (postId) => {
    const updatedPosts = posts.map((post) => {
      if (post.id === postId) {
        return { ...post, dislikes: post.dislikes + 1 };
      }
      return post;
    });
    setPosts(updatedPosts);
  };

  const startEditingComment = (postId, commentId, text) => {
    setEditingComment({ postId, commentId, text });
  };

  const saveEditedComment = () => {
    const { postId, commentId, text } = editingComment;
    const updatedPosts = posts.map((post) => {
      if (post.id === postId) {
        const updatedComments = post.comments.map((comment) =>
          comment.id === commentId ? { ...comment, text } : comment
        );
        return { ...post, comments: updatedComments };
      }
      return post;
    });
    setPosts(updatedPosts);
    setEditingComment({ postId: null, commentId: null, text: '' });
  };

  const deleteComment = (postId, commentId) => {
    const updatedPosts = posts.map((post) => {
      if (post.id === postId) {
        const updatedComments = post.comments.filter(
          (comment) => comment.id !== commentId
        );
        return { ...post, comments: updatedComments };
      }
      return post;
    });
    setPosts(updatedPosts);
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between mb-3">
        <button className="btn btn-primary">Topic Feed</button>
        <button className="btn btn-secondary">Filter Topics</button>
      </div>
      <input type="text" className="form-control mb-3" placeholder="Search" />

      {posts.map((post) => (
        <div key={post.id} className="card mb-4">
          <div className="card-header d-flex align-items-center">
            <img
              src={post.avatar}
              alt={post.user}
              className="rounded-circle mr-3"
              style={{ width: "50px", height: "50px" }}
            />
            <div>
              <h5 className="mb-0">{post.user}</h5>
              <small className="text-muted">{post.date}</small>
            </div>
          </div>
          <div className="card-body">
            <p>{post.content}</p>
          </div>
          <div className="card-footer">
            <div className="d-flex justify-content-between">
              <div>
                <button
                  className="btn btn-outline-primary btn-sm mr-2"
                  onClick={() => likePost(post.id)}
                >
                  Like ({post.likes})
                </button>
                <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={() => dislikePost(post.id)}
                >
                  Dislike ({post.dislikes})
                </button>
                <button
                  className="btn btn-outline-secondary btn-sm ml-3"
                  onClick={() => toggleComments(post.id)}
                >
                  Comments ({post.comments.length})
                </button>
              </div>
            </div>
            {openComments === post.id && (
              <div className="mt-3">
                <h6>Comments</h6>
                {post.comments.map((comment) => (
                  <div
                    key={comment.id}
                    className="d-flex justify-content-between align-items-center"
                  >
                    {editingComment.postId === post.id &&
                    editingComment.commentId === comment.id ? (
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          value={editingComment.text}
                          onChange={(e) =>
                            setEditingComment({
                              ...editingComment,
                              text: e.target.value,
                            })
                          }
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-primary"
                            onClick={saveEditedComment}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    ) : (
                      <p>{comment.text}</p>
                    )}
                    <div>
                      <button
                        className="btn btn-outline-secondary btn-sm mr-2"
                        onClick={() =>
                          startEditingComment(post.id, comment.id, comment.text)
                        }
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-outline-danger btn-sm"
                        onClick={() => deleteComment(post.id, comment.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
                <div className="input-group mt-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Add a comment"
                    ref={(el) => (inputRefs.current[post.id] = el)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && inputRefs.current[post.id]) {
                        addComment(post.id, inputRefs.current[post.id].value);
                        inputRefs.current[post.id].value = "";
                      }
                    }}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => {
                        const input = inputRefs.current[post.id];
                        if (input) {
                          addComment(post.id, input.value);
                          input.value = "";
                        }
                      }}
                    >
                      Post
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllFeed;
