import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";

const SignUp = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center py-4"
      style={{ height: "100%" }}
    >
      <div
        className="card p-3"
        style={{ width: "450px", boxShadow: "0px 0px 15px rgba(0,0,0,0.2)" }}
      >
        <div className="text-start mb-4">
          <img
            src="https://static.vecteezy.com/system/resources/previews/022/782/487/non_2x/loudspeaker-line-icon-with-sound-symbol-bullhorn-speaker-megaphone-icon-electric-megaphone-icon-loudspeaker-megaphone-announcement-concept-hand-mike-loud-speaker-marketing-advertising-free-vector.jpg"
            alt="Megaphone Icon"
            style={{ width: "100px", transform: "rotate(30deg)" }}
          />
          <h3
            style={{ color: "#323232", fontWeight: 600, fontSize: "40px" }}
            className="mt-2 w-50 text-3xl"
          >
            The Voice <br /> Of Bengal
          </h3>
        </div>
        <div>
          <h3 style={{ color: "#323232" }} className="text-start">
            Create New Account
          </h3>
        </div>
        <form>
          <div className="mb-3">
            <input
              style={{
                background: "#B6D3DB",
                color: "white",
                outline: "none",
                border: "none",
              }}
              type="text"
              className="form-control"
              placeholder="Name"
              required
            />
          </div>
          <div className="mb-3">
            <input
              style={{
                background: "#B6D3DB",
                color: "white",
                outline: "none",
                border: "none",
              }}
              type="email"
              className="form-control"
              placeholder="Email"
              required
            />
          </div>
          <div className="mb-3">
            <input
              style={{
                background: "#B6D3DB",
                color: "white",
                outline: "none",
                border: "none",
              }}
              type="text"
              className="form-control"
              placeholder="Contact no"
            />
          </div>
          <div className="mb-3">
            <input
              style={{
                background: "#B6D3DB",
                color: "white",
                outline: "none",
                border: "none",
              }}
              type="password"
              className="form-control"
              placeholder="Enter Password"
              required
            />
          </div>
          <div className="mb-3">
            <input
              style={{
                background: "#B6D3DB",
                color: "white",
                outline: "none",
                border: "none",
              }}
              type="password"
              className="form-control"
              placeholder="Re-enter Password"
              required
            />
          </div>

          <div className="d-grid mb-3">
            <button
              type="submit"
              style={{ background: "#455B79", color: "white" }}
              className="btn "
            >
              Sign Up
            </button>
          </div>
          <div className="d-grid">
            <button
              style={{ background: "#455B79", color: "white" }}
              type="button"
              className="btn btn-outline-secondary"
            >
              <i class="bi bi-google"></i> Signup with Google
            </button>
          </div>
          <div className="d-grid">
            <p className="pt-1 text-secondary">
              Already have a account?{" "}
              <Link style={{ color: "#455B79", fontWeight: 600 }} to="/signin">
                SignUp
              </Link>{" "}
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
