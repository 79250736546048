import React from "react";
import "./DevelopmentProjected.css";
const DevelopmentProjected = () => {
  return (
    <div>
      <div className="background">
        <h5 className="text">
          The development of the project is in progress...
        </h5>
      </div>
    </div>
  );
};

export default DevelopmentProjected;
