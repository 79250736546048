import React from "react";

const UserSearch = () => {
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h2 style={{ color: "#6E89AD" }}>Recent Post Search Here</h2>

        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            type="text"
            placeholder="Search here..."
            style={{
              background: "#D4E6E5",
              borderRadius: "40px",
              width: "450px",
              marginTop:"10px",
              height: "50px",
              paddingLeft: "10px",
              fontSize: "20px",
              border: "none",
              outline: "none",
            }}
          />
          <br />
          <div></div>
          <div style={{ textAlign: "right",marginTop:"20px" }}>
            <button
              style={{
                width: "150px",
                height: "40px",
                color: "white",
                background: "#7FB0CB",
                border: "none",
                borderRadius: "10px",
              }}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSearch;
