import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const UserTimeLine = () => {
  const user = {
    name: "John Doe",
    avatar: "https://via.placeholder.com/50",
    posts: [
      {
        id: 1,
        date: "August 16, 2024",
        content: "Had a great day at the beach!",
        image: "https://upload.wikimedia.org/wikipedia/commons/4/4d/12_-_The_Mystical_King_Cobra_and_Coffee_Forests.jpg",
        likes: 24,
        comments: 12,
      },
      {
        id: 2,
        date: "August 15, 2024",
        content: "Just finished reading a fantastic book!",
        image: "https://w0.peakpx.com/wallpaper/304/871/HD-wallpaper-kawasaki-ninja-h2r-bike-black-bike-parked-black-bike-parked-bike-thumbnail.jpg",
        likes: 15,
        comments: 8,
      },
      // Add more posts as needed
    ],
  };

  return (
    <div className="container">
      {/* <div className="d-flex align-items-center mb-4">
        <img
          src={user.avatar}
          alt={user.name}
          className="rounded-circle mr-3"
          style={{ width: "60px", height: "60px" }}
        />
        <h2>{user.name}</h2>
      </div> */}
      {user.posts.map((post) => (
        <div key={post.id} className="card mb-4">
          <div className="card-header">
            <small className="text-muted">{post.date}</small>
          </div>
          <div className="card-body">
            <p>{post.content}</p>
            {post.image && (
              <img src={post.image} alt="Post" className="img-fluid" />
            )}
          </div>
          <div className="card-footer d-flex justify-content-between">
            <div>
              <button className="btn btn-outline-primary btn-sm mr-2">
                Like ({post.likes})
              </button>
              <button className="btn btn-outline-secondary btn-sm">
                Comment ({post.comments})
              </button>
            </div>
            <button className="btn btn-outline-danger btn-sm">Share</button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserTimeLine;
